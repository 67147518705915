<template lang="pug">
.content
  section.container-fluid.m-t-20
  
    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item 案例管理
      
    .m-t-15.m-b-20
      h1.section-title.inline 案例管理
      a-button.m-l-15(size="small" @click="handleAdd") 新增案例
    
    vc-table.m-b-25(
      ref="table"
      :data="table"
      @change="handleTableChange")
    
</template>

<script>
//- import { mapState } from 'vuex'

//- import ComponentPage from './ComponentPage'

import columns from '@/config/column.config'
import vcTable from '@/components/vcTable'

export default {
  name: 'CaseManagement',
  components: {
    //- ComponentPage,
    'vc-table': vcTable,
  },
  computed: {
    //- ...mapState({
    //-   ui: state => state.ui
    //- })
  },
  data() {
    return {
      // 查询条件参数
      queryParam: {},

      /*********************************************************
      * @description 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'default',   // default | middle | small
        columns: columns.case(this),
        source: [],
        //- scroll: { x: 830 },

        api: parameter => {
          return this.$http.get('case', {
            params: Object.assign(parameter, this.queryParam)
          })
        },
      
        // 批量操作
        actions: [
          { label: '删除', value: 'delete' },
        ],

        // 过滤
        // filters: [],
        
        // 额外
        // extra: [
        //   { key: 'table-reload', label: '刷新', icon: 'reload' },
        //   { key: 'table-size', label: '密度', icon: 'column-height', component: 'table-size', data: { default: 'middle' } },
        //   { key: 'table-columns', label: '列设置', icon: 'setting', component: 'table-columns', data: column.page(this) },
        // ],
        
        // 分页器
        pagination: {
          total: 0,
          current: 1,
          pageSize: 15,
          pageSizeOptions: ['15', '25', '35'],
        },
      },
    }
  },
  filters: {},
  methods: {

    /*********************************************************
     * @description vc-table 对象方法
     *********************************************************/
    async handleTableChange({ action, pagination, filters, sorter }) {
      if (action) {
        switch(action.do) {
          case 'delete':
            await this.$http.delete('case', { data: { ids: action.data } });
            this.$message.success('删除成功');
            this.$refs.table.refresh();
          break;
        }
        return;
      }

      // keyword: this.table.keyword
      console.log('handleTableChange', { action, pagination, filters, sorter })
    },

    //- 新增/编辑操作方法
    handleAdd() {
      const route = this.$router.getRoutes().find(item => item.name === 'Case Add');
      if (route && this.$route.name !== 'Case Add') return this.$router.push({ name: 'Case Add' });
      console.log('handle add')
    },
    async handleEdit(id) {
      const route = this.$router.getRoutes().find(item => item.name === 'Case Edit');
      if (route && this.$route.name !== 'Case Edit') return this.$router.push({ name: 'Case Edit', params: { id } });
      console.log('handle edit')
    },

    /*********************************************************
     * @description 获取单个数据
     *********************************************************/
    async getDetail(id) {
      return await this.$http.get(`page/${id}`);
    },

    /*********************************************************
     * @description 删除单个数据
     *********************************************************/
    async deleteData(id) {
      await this.$http.delete(`page/${id}`);
      this.$message.success('删除成功');
      this.$refs.table.refresh();
    },
  },
  created() {
    //- this.tableOption()
    //- getRoleList({ t: new Date() })
  },
}
</script>